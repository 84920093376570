import { redirect } from '@vercel/remix';
import type { LoaderFunction } from '@vercel/remix';

export const config = { runtime: 'edge' };

export const loader: LoaderFunction = async () => {
  return redirect( '/dashboard' );
};

export default function Index() {
  return null;
}
